<script>
  import {Button, ButtonToolbar, Card, CardBody, Modal, ModalBody, Spinner, Row, Col} from "sveltestrap";
  import {_} from "svelte-i18n";
  import {onDestroy} from "svelte";
  import {navigate} from 'svelte-routing';
  import Time from "svelte-time";
  import {chosenChannel} from "../../helpers/store";
  import {layout} from "../../services/loaders";
  import {addToast} from "../../helpers/toast";
  import {SegmentDAO, segmentStore} from "../../helpers/onetouch/SegmentDAO";
  import NoChannelsWrapper from "../../Components/NoChannelsWrapper.svelte";
  import ChooseTheChannel from "../../Components/Modals/ChooseTheChannelModal.svelte";
  import TableWithPagination from "../../Components/TableWithPagination.svelte";

  let chosenChannelLocal;

  let segmentsLoader = true;
  let search = '';

  let chosenDeleteSegment = null;
  let loadingDeleteSegment = false;

  const unsub3 = chosenChannel.subscribe(value => {
    if (value === null || value === "") {
      chosenChannelLocal = null;
    } else {
      if (chosenChannelLocal?.id !== value?.id) {
        segmentsLoader = true;
        layout(segmentsLoader);
        search = '';
        chosenChannelLocal = value
        SegmentDAO.getOrLoadAll({
          limit: 1000000,
          ordering: '-id'
        }).then(() => {
          segmentsLoader = false;
          layout(segmentsLoader)
        })
      }
    }
  });

  onDestroy(() => {
    unsub3();
  });

  const onSearch = () => {
    return $segmentStore.filter(item => item.name.toLowerCase().includes(search.toLowerCase()));
  };

  const deleteRequestSegment = () => {
    loadingDeleteSegment = true;
    SegmentDAO.delete(chosenDeleteSegment.id, {}, true).then(() => {
      addToast({title: $_("segments.delete_success")});
      chosenDeleteSegment = null;
      loadingDeleteSegment = false
    })
  };
</script>

<NoChannelsWrapper>
<div class="page-content px-0 px-sm-2">
  {#if !segmentsLoader && $segmentStore?.length}
    <div class="container-fluid">
      <h4 class="mb-4 font-size-20 fw-bold">
        {$_('segments.title')}
      </h4>
      <Card>
        <CardBody>
          <Row class="flex-column-reverse flex-md-row">
            <Col md={8} class="mb-3 d-flex flex-wrap align-items-center gap-3">
              <div class="position-relative w-100">
                <input type="text" placeholder={$_('search')} bind:value={search}
                  class="border-1 rounded-2 text-muted form-control Search"/>
                <i class="bx bx-search-alt position-absolute font-size-20 text-muted top-0"/>
              </div>
            </Col>
            <Col md={4} class="mb-3 d-flex justify-content-md-end align-items-start flex-wrap gap-3">
              <Button color="success" class="py-2 px-3 d-flex" on:click={() => navigate('/segments/create')}>
                <i class="bx bx-plus me-2 font-size-20"/>
                <span class="font-size-14">{$_('segments.add')}</span>
              </Button>
            </Col>
          </Row>
          <TableWithPagination>
            <tr slot="thead">
              <th style="width: 25%">{$_('segments.segment_name')}</th>
              <th class="text-center" style="width: 25%">{$_('segments.table.contacts_count')}</th>
              <th class="text-center" style="width: 25%">{$_('segments.table.date')}</th>
              <th class="text-center" style="width: 25%">{$_('segments.table.action')}</th>
            </tr>
            <tbody slot="tbody">
              {#each (search ? onSearch() : $segmentStore) as segment}
                <tr class="font-size-15 fw-500">
                  <td class="text-truncate pointer NameSegment" title={segment.name} on:click={() => {
                    navigate(`/segments/edit/${segment.id}`)
                  }}>
                    {segment.name}
                  </td>
                  <td class="text-center">
                    {segment.segment_list_user.length}
                  </td>
                  <td class="text-center">
                    <Time timestamp={new Date(segment.created_at * 1000)} format="DD.MM.YYYY"/>
                  </td>
                  <td>
                    <ButtonToolbar class="justify-content-center align-items-center flex-nowrap gap-4">
                      <!-- <button class="d-flex p-0 border-0 bg-transparent font-size-24">
                        <i class="bx bx-mail-send"/>
                      </button> -->
                      <button class="d-flex p-0 border-0 bg-transparent font-size-24"
                        title={$_('segments.edit')}
                        on:click={() => navigate(`/segments/edit/${segment.id}`)}>
                        <i class="bx bx-edit"/>
                      </button>
                      <button class="d-flex p-0 border-0 bg-transparent font-size-24"
                        title={$_('segments.copy')}
                        on:click={() => navigate(`/segments/create/${segment.id}`)}>
                        <i class="bx bx-copy-alt"/>
                      </button>
                      <button class="d-flex p-0 border-0 bg-transparent font-size-24"
                        title={$_('segments.delete')}
                        on:click={() => chosenDeleteSegment = segment}>
                        <i class="bx bx-trash"/>
                      </button>
                    </ButtonToolbar>
                  </td>
                </tr>
              {/each}
            </tbody>
          </TableWithPagination>
        </CardBody>
      </Card>
    </div>
  {/if}
  {#if chosenDeleteSegment}
    <Modal isOpen={chosenDeleteSegment} fade={true} toggle={() => {
      if (!loadingDeleteSegment) chosenDeleteSegment = null
    }} centered>
      <ModalBody>
        <h2 class="fw-bold mb-3 text-center">
          {$_('segments.delete')}
        </h2>
        <div class="mb-3 text-center">
          {@html $_("segments.delete_text", {values: {segment: `"${chosenDeleteSegment.name}"`}})}
        </div>
        <div class="text-center">
          <span class="d-inline-block me-3 font-size-14 pointer text-success" on:click={() => {
            if (!loadingDeleteSegment) chosenDeleteSegment = null
          }}>
            {$_("no")}
          </span>
          <Button color="danger" class="font-size-16 text-uppercase py-1 px-4"
            disabled={loadingDeleteSegment} on:click={deleteRequestSegment}>
            {#if loadingDeleteSegment}
              <Spinner size="sm"/>
            {:else}
              {$_("yes")}
            {/if}
          </Button>
        </div>
      </ModalBody>
    </Modal>
  {/if}
  {#if !segmentsLoader && !$segmentStore?.length}
    <div class="d-flex align-items-center justify-content-center text-center noSegment">
      <div class="p-3">
        <img src="/assets/images/wait_segments.svg" alt="wait for segments" class="mb-4 w-100"/>
        <div class="font-size-22 fw-500 mb-3">
          {$_('segments.create_text')}
        </div>
        <Button color="success" class="d-flex align-items-center gap-2 px-3 font-size-18 mx-auto"
          on:click={() => navigate('/segments/create')}>
          <i class="bx bx-plus lh-1"/>
          {$_('segments.add')}
        </Button>
      </div>
    </div>
  {/if}
</div>
</NoChannelsWrapper>
<ChooseTheChannel/>

<style>
    .Search {
        padding-left: 40px;
        max-width: 500px;
    }

    .bx-search-alt {
        top: 0;
        left: 11px;
        line-height: 40px;
    }

    .noSegment {
        height: calc(100vh - 94px - 60px);
    }

    .noSegment img {
        max-width: 336px;
        min-height: 316px;
    }

    .NameSegment {
        max-width: 200px;
    }

    tbody tr, tbody button {
        color: #74788D;
    }

    tbody button:hover {
        color: #23B16D;
    }
</style>

<script>
  import {FormGroup, Label, Input, Button, Row, Col, DropdownItem} from "sveltestrap";
  import {_} from "svelte-i18n";
  import SelectDropdown from "../../../../Components/SelectDropdown.svelte";
  import SelectDropdownCheckbox2 from "../../../../Components/SelectDropdownCheckbox2.svelte";
  import ChoseTemplate from "./ChoseTemplate.svelte";
  import {BroadcastStatus} from "../../../../helpers/onetouch/BroadcastDAO";
  import {appClient, channels} from "../../../../helpers/store";
  import {onDestroy, onMount} from "svelte";
  import {TagsDAO, tagsStore} from "../../../../helpers/onetouch/TagsDAO";
  import {SegmentDAO, segmentStore} from "../../../../helpers/onetouch/SegmentDAO";
  import ModalCreateSemgent from "../../../Segments/ModalCreateSemgent.svelte";

  /**
   * @type {BroadcastData}
   */
  export let data;
  export let error;
  export let isDisabled;
  export let onDraftSave;
  export let onBroadcastStart;

  let isOpenCreateNewSegment = false;

  let listShopId = [];
  let listTags = []
  let listSegments = []

  let whenToSend;
  let localDataDate;
  let localDataTime;

  TagsDAO.getOrLoadAll()
  SegmentDAO.getOrLoadAll({
    limit: 1000000,
    ordering: '-id'
  })


  onMount(() => {
    whenToSend = data?.starts_at ? 1 : 0
    const startTime = !!data?.starts_at ? data.starts_at * 1000 : new Date().getTime()
    localDataDate = new Date(startTime).toISOString().split('T')[0]
    localDataTime = new Date(startTime).toISOString().substr(11, 5)
  })
  const unsub = tagsStore.subscribe(tags => {
    listTags = Array.isArray(tags) && tags.length ? tags.reduce(
      (result, item) => {
        result.push(
          {value: item.id, label: item.name}
        );
        return result;
      },
      []
    ) : [];
  })

  const unsub3 = segmentStore.subscribe(tags => {
    listSegments = Array.isArray(tags) && tags.length ? tags.reduce(
      (result, item) => {
        result.push(
          {
            value: item.id,
            label: `${item.name}: <span class="text-success">${item.segment_list_user.length ?? 0} recipients</span>`
          }
        );
        return result;
      },
      []
    ) : [];
  })

  const unsub2 = channels.subscribe(async (value) => {
    listShopId = Array.isArray(value) && value.length ? value.reduce(
      (result, item) => {
        if (item.appData)
          result.push(
            {value: appClient.getCommonVars(item).shopId, label: `${item.id} ${item.name}`}
          );
        return result;
      },
      []
    ) : [];

  })


  function handleDateTimeChange(event, isDate = false) {
    const {value} = event.target;
    let updatedDate;
    let updatedTime;

    if (isDate) {
      updatedDate = value;
      updatedTime = localDataTime;
    } else {
      updatedDate = localDataDate;
      updatedTime = value;
    }

    const updatedTimestamp = new Date(`${updatedDate}T${updatedTime}Z`).getTime();
    if (!isNaN(updatedTimestamp)) {
      data.starts_at = updatedTimestamp / 1000;
    }
  }

  function restoreDateFromLocalData() {
    const updatedTimestamp = new Date(`${localDataDate}T${localDataTime}Z`).getTime();
    if (!isNaN(updatedTimestamp)) {
      data.starts_at = updatedTimestamp / 1000;
    }
  }


  onDestroy(() => {
    unsub2();
    unsub();
    unsub3();
  });
</script>

<form on:submit|preventDefault={() => onBroadcastStart && onBroadcastStart()}>
  <FormGroup>
    <Label class="fw-600" for="broadcast_name">
      {$_('broadcast.broadcast_name')}
    </Label>
    <Input bind:value={data.name}
      class={error.name?'is-invalid': ''}
      disabled={isDisabled}
      name="broadcast_name"
      type="text" required/>
  </FormGroup>
  <FormGroup>
    <Label class="fw-600" for="channel_selection">
      {$_('broadcast.channel_selection')}
    </Label>
    <SelectDropdown bind:selected={data.shop_id}
      error={error.shop_id}
      disabled={isDisabled}
      list={listShopId}
      title='broadcast.select' required/>
  </FormGroup>
  <FormGroup>
    <Label class="fw-600" for="segment_selection">
      {$_('broadcast.segment_selection')}
    </Label>
    <SelectDropdown bind:selected={data.segment_id}
      error={error.segment_id}
      disabled={isDisabled}
      list={listSegments}
      title='broadcast.select' required>
      <DropdownItem class="text-truncate" on:click={()=> isOpenCreateNewSegment=true}>
        {$_('broadcast.create_new_segment')}
      </DropdownItem>
    </SelectDropdown>
  </FormGroup>
  <FormGroup>
    <Label class="fw-600">
      {$_('broadcast.template_selection')}
    </Label>
    <!--CHOOSE TEMPLATE MODAL-->
    <ChoseTemplate bind:data disabled={isDisabled} required/>
    <!--    *********************-->
  </FormGroup>
  <FormGroup>
    <div class="d-flex flex-wrap justify-content-between">
      <Label class="fw-600" for="tag_selection">
        {$_('broadcast.add_tags')}
      </Label>
      {#if !isDisabled}
        <a href="/automation/flow/settings_tags_create/" target="_blank" class="font-size-14 pointer text-success">
          {$_("chat_inbox.UserCard.create_tags")}
        </a>
      {/if}
    </div>
    <SelectDropdownCheckbox2 bind:value={data.tags}
      error={error.tags}
      disabled={isDisabled}
      list={listTags}
      click={() => TagsDAO.loadAll()}
      title='broadcast.select'/>
  </FormGroup>
  <FormGroup>
    <Label class="fw-600">
      {$_('broadcast.when_to_send')}
    </Label>
    <div class="form-check mb-2">
      <input bind:group={whenToSend} class="form-check-input" disabled={isDisabled}
        id="send_it_now" on:click={()=>{data.starts_at = 0;}} type="radio" value={0}>
      <label class="form-check-label" for="send_it_now">
        {$_('broadcast.send_it_now')}
      </label>
    </div>
    <div class="form-check">
      <input bind:group={whenToSend} class="form-check-input" disabled={isDisabled}
        id="schedule_for_a_special_time" on:click={restoreDateFromLocalData} type="radio" value={1}>
      <label class="form-check-label" for="schedule_for_a_special_time">
        {$_('broadcast.schedule_for_a_special_time')}
      </label>
    </div>
  </FormGroup>
  {#if whenToSend}
    <Col xl={8}>
      <Row>
        <Col xl={6}>
          <FormGroup>
            <Label for="date" class="fw-600">
              {$_('broadcast.date')}
            </Label>
            <Input class="custom-input-calendar" type="date" name="date" disabled={isDisabled}
              bind:value={localDataDate} on:change={(event) => handleDateTimeChange(event, true)}/>
          </FormGroup>
        </Col>
        <Col xl={6}>
          <FormGroup>
            <Label for="date" class="fw-600">
              {$_('broadcast.time')}
            </Label>
            <Input class="custom-input-time" type="time" name="time" disabled={isDisabled}
              bind:value={localDataTime} on:change={handleDateTimeChange}/>
          </FormGroup>
        </Col>
      </Row>
    </Col>
  {/if}
  {#if !isDisabled}
    <div>
      <div class="custom-alert custom-alert-primary mb-4">
        <i class="mdi mdi-alert-circle-outline"/>{$_(`broadcast.alert`)}
      </div>
      <div class="d-flex gap-3 flex-wrap">
        <Button type="submit" color="success" class="py-2 px-4">
          <span class="font-size-14">{$_('send')}</span>
        </Button>
        <Button type="button" outline color="success" class="py-2 px-4"
          on:click={() => onDraftSave && onDraftSave()}>
          <span class="font-size-14">{$_('broadcast.save_draft_and_exit')}</span>
        </Button>
      </div>
    </div>
  {/if}
</form>

<ModalCreateSemgent bind:isOpen={isOpenCreateNewSegment} newSegment={segment => data.segment_id = segment.id} />

<style>
  .form-check-input[type=radio]:checked {
    background-color: #fff;
    border-color: #34C38F;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%2334C38F'/%3e%3c/svg%3e");
  }

  .form-check-input[type=radio]:disabled ~ .form-check-label {
    opacity: 1;
  }

  .form-check-input[type=radio]:focus {
    box-shadow: 0 0 0 0.15rem rgba(52, 195, 143, .25);
  }
</style>
